<template>
	<div class="bingomenu">
		<div  :class="{ 'item': true, current: 'addbingo' === lastname }" @click="toUrl('addbingo')">发布并购项目</div>
		<div   :class="{ 'item': true, current: 'addxuqiu' === lastname }" @click="toUrl('addxuqiu')">发布需求项目</div>
		<div :class="{ 'item': true, current: 'addnong' === lastname }" @click="toUrl('addnong')">发布农地流转</div>
		<div :class="{ 'item': true, current: 'mine' === lastname }" @click="toUrl('mine')">我的发布</div>
		<div    :class="{ 'item': true, current: 'jjrinfo' === lastname }" @click="toUrl('jjrinfo')">经纪人资料</div>

</div>
</template>

<script>


import { detail, save } from '@/api/website/regjjr.js'

export default {
	name: 'PcWebsiteBingomenu',

	data() {
		return {
			lastname: '',
			examine:false
		};
	},
	watch: {
		$route: {
			handler(nv) {
				let lastname = nv.path.split('/').pop()
				this.lastname = lastname
			},
			deep: true
		}
	},

	mounted() {
		let lastname = this.$route.path.split('/').pop()
	

		this.lastname = lastname


		detail().then(aa => {
			if (aa.data.examine === 1) {
				// this.$router.push('/cn/list/jjrzc')
				this.examine = true
			}else{
				this.examine = false
			}

		})


	},

	methods: {

		toUrl(url) {

			if(this.examine){
				this.$router.push('/cn/list/' + url)
			}else{
			
				if(url === 'addnong' || url === 'mine'){
					this.$router.push('/cn/list/' + url)
				}else{
					const newurl = this.$router.resolve('/cn/list/' + url)
					window.open(newurl.href, "_blank"); 
				}
			
			}
			
		}

	},
};
</script>

<style lang="scss" scoped>
.bingomenu {
	.item {
		cursor: pointer;
		width: 260px;
		height: 52px;
		background: #FFFFFF;
		text-align: left;
		padding-left: 30px;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 52px;
		box-sizing: border-box;
		margin-right: 4px;
		margin-bottom: 4px;
	}

	.current {
		background: #28A7E1;
		color: #FFFFFF;
	}
}
</style>